import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "lucide-react";
import img1 from "../Assest/wedswipe/b7.png";
import img2 from "../Assest/wedswipe/b8.png";
import img3 from "../Assest/wedswipe/b9.png";
import img4 from "../Assest/wedswipe/b10.png";
import img5 from "../Assest/wedswipe/b11.png";
import img6 from "../Assest/wedswipe/b6.png";
import img7 from "../Assest/wedswipe/b12.png";
import img8 from "../Assest/wedswipe/b13.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const imagesArr = [
  {
    id: 1,
    img: img6,
    content:
      "At Vision Event, we believe every wedding is a story waiting to be told. From the first spark of inspiration to the final farewell, we are here to bring your vision to life with elegance, passion, and precision.",
  },
  {
    id: 2,
    img: img1,
    content:
      "Your wedding is a reflection of your unique love story, and we are dedicated to making it unforgettable. With Vision Event by your side, your dreams become reality, and every moment becomes a cherished memory.",
  },
  {
    id: 3,
    img: img2,
    content:
      "Great weddings are built on the smallest details. At Vision Event, we obsess over every element—big or small—to ensure your special day is a seamless blend of beauty, tradition, and joy.",
  },
  {
    id: 4,
    img: img3,
    content:
      "Planning your wedding should be as enjoyable as the day itself. With Vision Event handling every detail, you can relax and immerse yourself in the joy of your journey toward forever.",
  },
  {
    id: 5,
    img: img4,
    content:
      "Transforming occasions into unforgettable moments. From weddings to surprise parties, we craft unforgettable moments with bespoke decorations tailored to your vision.",
  },
  {
    id: 6,
    img: img5,
    content:
      "Indian weddings are a beautiful tapestry of tradition and modernity. At Vision Event, we honor your culture while adding a contemporary flair, creating a celebration that respects the past and embraces the future.",
  },
  {
    id: 7,
    img: img7,
    content:
      "Your happiness is our ultimate goal. From our first conversation to the final goodbye, Vision Event is committed to providing a personalized experience that exceeds your expectations and fills your heart with joy.",
  },
  {
    id: 8,
    img: img8,
    content:
      "Weddings are more than just events—they are moments that define a lifetime. Vision Event is dedicated to creating an experience that stays with you and your guests long after the last dance.",
  },
];

const HeroSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section className="relative w-full h-[100vh]">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        className="w-full h-full group"
        loop={true}
        grabCursor={true}
        speed={800}
        slidesPerView={1}
        spaceBetween={0}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {imagesArr.map((image, index) => (
          <SwiperSlide key={index} className="relative w-full h-full">
            <div className="relative w-full h-full overflow-hidden">
              <img
                src={image.img}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover object-center transition-transform duration-500 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black/20"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                <p className="text-3xl font-semibold w-full sm:w-4/5">{image.content}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}

        {/* Navigation Buttons */}
        <div className="absolute inset-0 z-10 flex items-center justify-between px-4">
          <button
            ref={prevRef}
            className="w-12 h-12 rounded-full bg-white/80 hover:bg-white flex items-center justify-center transition-all duration-300 shadow-lg transform hover:scale-110 group"
            aria-label="Previous slide"
          >
            <ChevronLeft className="w-6 h-6 text-gray-800 group-hover:text-gray-900" />
          </button>
          <button
            ref={nextRef}
            className="w-12 h-12 rounded-full bg-white/80 hover:bg-white flex items-center justify-center transition-all duration-300 shadow-lg transform hover:scale-110 group"
            aria-label="Next slide"
          >
            <ChevronRight className="w-6 h-6 text-gray-800 group-hover:text-gray-900" />
          </button>
        </div>
      </Swiper>

      <div className="swiper-pagination absolute bottom-6 w-full flex justify-center gap-2 z-10"></div>

      <style jsx>
        {`
          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            transition: all 0.3s ease;
            cursor: pointer;
          }
          .swiper-pagination-bullet-active {
            background: white;
            transform: scale(1.2);
          }
        `}
      </style>
    </section>
  );
};

export default HeroSection;
